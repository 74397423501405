import { useContext } from "react";
import { useGetIdentity } from "@refinedev/core";
import {
    Layout as AntdLayout,
    Space,
    Avatar,
    Typography,
    Switch,
    theme,
} from "antd";
import { ColorModeContext } from "../../contexts/color-mode";

const { Text } = Typography;
const { useToken } = theme;

type IUser = {
    id: number;
    username: string;
    avatar: string;
};

export const Header: React.FC = () => {
    const { token } = useToken();
    const { data: user } = useGetIdentity<IUser>();
    const { mode, setMode } = useContext(ColorModeContext);

    return (
        <AntdLayout.Header
            style={{
                backgroundColor: token.colorBgElevated,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "0px 24px",
                height: "64px",
            }}
        >
            <Space>
                <Switch
                    checkedChildren="🌛"
                    unCheckedChildren="🔆"
                    onChange={() =>
                        setMode(mode === "light" ? "dark" : "light")
                    }
                    defaultChecked={mode === "dark"}
                />
                <Space style={{ marginLeft: "8px" }} size="middle">
                    {user?.username && <Text strong>{user.username}</Text>}
                    {user?.avatar && (
                        <Avatar src={user?.avatar} alt={user?.username} />
                    )}
                </Space>
            </Space>
        </AntdLayout.Header>
    );
};
