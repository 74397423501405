import React from "react";
import { IResourceComponentsProps, BaseRecord, CrudFilters } from "@refinedev/core";
import {
    useTable,
    List,
    EditButton,
    BooleanField,
    DateField,
    DeleteButton,
    FilterDropdown,
} from "@refinedev/antd";
import { Table, Space, Input } from "antd";
import { FilterDropdownProps } from "antd/es/table/interface";

export const GiftGroupList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps } = useTable({
        syncWithLocation: true,
        initialSorter: [
            {
                field: 'updateDate',
                order: 'asc',
            },
        ],
        initialFilter: [],
    });

    return (
        <List>
            <Table {...tableProps} rowKey="id">
                <Table.Column dataIndex="id" title="Id"/>
                <Table.Column
                    dataIndex="title"
                    title="Tên nhóm quà"
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex={["isForRegistered"]}
                    title="Dành cho đăng ký gói"
                    render={(value: any) => <BooleanField value={value} />}
                />
                <Table.Column
                    dataIndex={["isForExperience"]}
                    title="Dành cho trải nghiệm"
                    render={(value: any) => <BooleanField value={value} />}
                />
                <Table.Column
                    dataIndex={["updateDate"]}
                    title="Ngày sửa gần nhất"
                    render={(value: any) => <DateField value={value} />}
                />
                <Table.Column
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};