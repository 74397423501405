import React from "react";
import { IResourceComponentsProps, BaseRecord } from "@refinedev/core";
import {
    useTable,
    List,
    EditButton,
    ImageField,
    DateField,
    DeleteButton,
} from "@refinedev/antd";
import { Table, Space } from "antd";

import { BASE_URL } from "authProvider";
import { GiftTypeLabel } from "dataProvider/constants";

export const GiftList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps } = useTable({
        syncWithLocation: true,
    });

    return (
        <List>
            <Table {...tableProps} rowKey="id">
                <Table.Column dataIndex="id" title="Id" />
                <Table.Column dataIndex="title" title="Tên quà" />
                <Table.Column
                    dataIndex={["image"]}
                    title="Ảnh"
                    render={(value: any) => (
                        <ImageField
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                            value={`${BASE_URL}/${value}`}
                        />
                    )}
                />
                <Table.Column
                    dataIndex={["giftGroup", "title"]}
                    title="Nhóm quà"
                />
                <Table.Column
                    dataIndex={["type"]}
                    title="Loại quà"
                    render={(value: string) => {
                        // @ts-ignore
                        return <span>{GiftTypeLabel[value]}</span>;
                    }}
                />
                <Table.Column
                    dataIndex={["updateDate"]}
                    title="Ngày sửa gần nhất"
                    render={(value: any) => <DateField value={value} />}
                />
                <Table.Column
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
