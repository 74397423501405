// import React from "react";
// import { IResourceComponentsProps, useApiUrl } from "@refinedev/core";
// import { Edit, useForm, getValueFromEvent, useSelect } from "@refinedev/antd";
// import { Form, Input, Upload, DatePicker, Select } from "antd";
// import dayjs from "dayjs";
// import { UploadChangeParam, UploadFile } from "antd/es/upload";
// import { BASE_URL, TOKEN_KEY } from "authProvider";

// export const GiftConfigEdit: React.FC<IResourceComponentsProps> = () => {
//     const { formProps, saveButtonProps, queryResult } = useForm();

//     const giftData = queryResult?.data?.data;

//     const { selectProps: giftGroupSelectProps } = useSelect({
//         resource: "gift-groups",
//     });
//     const apiUrl = useApiUrl();

//     return (
//         <Edit saveButtonProps={saveButtonProps}>
//             <Form {...formProps} layout="vertical">
//                 <Form.Item
//                     label="Id"
//                     name={["id"]}
//                     rules={[
//                         {
//                             required: true,
//                         },
//                     ]}
//                 >
//                     <Input readOnly disabled />
//                 </Form.Item>
//                 <Form.Item
//                     label="Tên quà"
//                     name={["title"]}
//                     rules={[
//                         {
//                             required: true,
//                         },
//                     ]}
//                 >
//                     <Input />
//                 </Form.Item>
//                 <Form.Item
//                     label="Ảnh"
//                 >
//                     <Form.Item
//                         label="Ảnh"
//                         name="image"
//                         getValueFromEvent={(event: UploadChangeParam): UploadFile[] => {
//                             const { fileList } = event;
//                             return fileList[0].response?.data?.url;
//                         }}
//                         noStyle
//                         rules={[
//                             {
//                                 required: true,
//                             },
//                         ]}
//                     >
//                         <Upload.Dragger
//                             name="file"
//                             action={`${apiUrl}/upload/single`}
//                             listType="picture"
//                             headers={{
//                                 'Authorization': `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
//                             }}
//                             maxCount={1}
//                         >
//                             <p className="ant-upload-text">
//                                 Drag & drop a file in this area
//                             </p>
//                         </Upload.Dragger>
//                     </Form.Item>
//                 </Form.Item>
//                 <Form.Item
//                     label="Nhóm quà"
//                     name={["giftGroupId"]}
//                     rules={[
//                         {
//                             required: true,
//                         },
//                     ]}
//                 >
//                     <Select {...giftGroupSelectProps} />
//                 </Form.Item>
//             </Form>
//         </Edit>
//     );
// };
import React from "react";
import { IResourceComponentsProps, useList } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, DatePicker } from "antd";
import dayjs from "dayjs";

export const GiftConfigEdit: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, queryResult } = useForm();

    const giftConfigData = queryResult?.data?.data;

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Id"
                    name={["id"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input readOnly disabled />
                </Form.Item>
                <Form.Item
                    label="Title"
                    name={["title"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input readOnly disabled />
                </Form.Item>
                <>
                    {(giftConfigData?.giftConfigs || []).map(
                        (giftConfig: any, index: number) => {
                            return (
                                <div key={index}>
                                    <Form.Item
                                        key={`${index}_id`}
                                        label=''
                                        name={["giftConfigs", index, "id"]}
                                        hidden
                                    >
                                        <Input type="text" />
                                    </Form.Item>
                                    <Form.Item
                                        key={`${index}_quantity`}
                                        label={`Số lượng quà '${giftConfig.title}'`}
                                        name={["giftConfigs", index, "quantity"]}
                                    >
                                        <Input type="text" defaultValue={0} />
                                    </Form.Item>
                                </div>
                            );
                        },
                    )}
                </>
            </Form>
        </Edit>
    );
};
