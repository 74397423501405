import React, { useEffect } from "react";
import { IResourceComponentsProps, useApiUrl, useList } from "@refinedev/core";
import { Create, useForm, getValueFromEvent, useSelect } from "@refinedev/antd";
import { Form, Input, Upload, DatePicker, Select, Checkbox, InputNumber } from "antd";
import { TOKEN_KEY } from "authProvider";
import { UploadChangeParam } from "antd/es/upload";
import { UploadFile } from "antd/lib/upload";
import { BASE_URL } from "authProvider";

export const CheckpointCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, queryResult, form } = useForm();

    const { data } = useList({
        resource: 'checkpoints',
    });

    useEffect(() => {
        form.setFieldsValue({
            order: (data?.total || 0) + 1,
            title: '',
            isEnabled: false,
            isRequired: false,
            isRegistered: false,
        });
    }, [data]);

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Số thứ tự"
                    name={["order"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber min={1} />
                </Form.Item>
                <Form.Item
                    label="Tên trải nghiệm"
                    name={["title"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Bật/tắt trải nghiệm"
                    valuePropName="checked"
                    name={["isEnabled"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Checkbox>Bật</Checkbox>
                </Form.Item>
                <Form.Item
                    label="Trải nghiệm"
                    valuePropName="checked"
                    name={["isRequired"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Checkbox>Cần để đủ điều kiện nhận quà</Checkbox>
                </Form.Item>
                <Form.Item
                    label="Trải nghiệm đăng ký gói"
                    valuePropName="checked"
                    name={["isRegistered"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Checkbox>Người dùng đăng ký gói</Checkbox>
                </Form.Item>
            </Form>
        </Create>
    );
};
