import React from "react";
import { IResourceComponentsProps, BaseRecord } from "@refinedev/core";
import {
    useTable,
    List,
    EditButton,
    ImageField,
    DateField,
    DeleteButton,
} from "@refinedev/antd";
import { Table, Space } from "antd";

import { BASE_URL } from "authProvider";

export const GiftConfigList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps } = useTable({
        syncWithLocation: true,
        pagination: {
            mode: 'off',
        }
    });

    return (
        <List>
            <Table {...tableProps} rowKey="id">
                <Table.Column dataIndex="id" title="Id" />
                <Table.Column dataIndex="title" title="Tên địa điểm" />
                <Table.Column
                    dataIndex={["totalGiftCount"]}
                    title="Số lượng quà còn lại"
                />
                <Table.Column
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
