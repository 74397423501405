import React from "react";
import { IResourceComponentsProps, BaseRecord, useMany, getDefaultSortOrder } from "@refinedev/core";
import {
    useTable,
    List,
    EditButton,
    ShowButton,
    BooleanField,
    DateField,
    FilterDropdown,
    DeleteButton,
} from "@refinedev/antd";
import { Table, Space, Input } from "antd";

import { FilterDropdownProps } from "antd/lib/table/interface";

export const CheckpointList: React.FC<IResourceComponentsProps> = () => {
    const { tableProps, sorter, filters } = useTable({
        syncWithLocation: true,
        initialSorter: [],
        initialFilter: [],
    });

    return (
        <List>
            <Table {...tableProps} rowKey="id">
                <Table.Column dataIndex="id" title="Id" />
                <Table.Column
                    dataIndex={["order"]}
                    title="Số thứ tự"
                />
                <Table.Column
                    dataIndex="title"
                    title="Tên trải nghiệm"
                    sorter
                    filterDropdown={(props: FilterDropdownProps) => (
                        <FilterDropdown {...props}>
                            <Input />
                        </FilterDropdown>
                    )}
                />
                <Table.Column
                    dataIndex={["isEnabled"]}
                    title="Bật/tắt trải nghiệm"
                    render={(value: any) => <BooleanField value={value} />}
                />
                <Table.Column
                    dataIndex={["isRequired"]}
                    title="Trải nghiệm"
                    render={(value: any) => <BooleanField value={value} />}
                />
                <Table.Column
                    dataIndex={["isRegistered"]}
                    title="Trải nghiệm đăng ký gói"
                    render={(value: any) => <BooleanField value={value} />}
                />
                <Table.Column
                    dataIndex={["updateDate"]}
                    title="Ngày sửa gần nhất"
                    render={(value: any) => <DateField value={value} />}
                />
                <Table.Column
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
