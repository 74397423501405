import React, { useEffect } from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, Checkbox, DatePicker } from "antd";
import dayjs from "dayjs";

export const GiftGroupCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, queryResult, form } = useForm();

    useEffect(() => {
        form.setFieldsValue({
            title: '',
            isForRegistered: false,
            isForExperience: false,
        });
    }, []);

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Tên nhóm quà"
                    name={["title"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Dành cho đăng ký gói"
                    valuePropName="checked"
                    name={["isForRegistered"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Checkbox>Random quà cho mã QR đã đăng ký gói</Checkbox>
                </Form.Item>
                <Form.Item
                    label="Dành cho trải nghiệm"
                    valuePropName="checked"
                    name={["isForExperience"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Checkbox>Random quà cho mã QR chưa đăng ký gói</Checkbox>
                </Form.Item>
            </Form>
        </Create>
    );
};
