import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Input, Checkbox, DatePicker, InputNumber } from "antd";
import dayjs from "dayjs";

export const CheckpointEdit: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, queryResult } = useForm();

    const checkpointsData = queryResult?.data?.data;

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Id"
                    name={["id"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input readOnly disabled />
                </Form.Item>
                <Form.Item
                    label="Số thứ tự"
                    name={["order"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <InputNumber min={1} />
                </Form.Item>
                <Form.Item
                    label="Tên trải nghiệm"
                    name={["title"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Bật/tắt trải nghiệm"
                    valuePropName="checked"
                    name={["isEnabled"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Checkbox>Bật</Checkbox>
                </Form.Item>
                <Form.Item
                    label="Trải nghiệm"
                    valuePropName="checked"
                    name={["isRequired"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Checkbox>Cần để đủ điều kiện nhận quà</Checkbox>
                </Form.Item>
                <Form.Item
                    label="Trải nghiệm đăng ký gói"
                    valuePropName="checked"
                    name={["isRegistered"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Checkbox>Người dùng đăng ký gói</Checkbox>
                </Form.Item>
            </Form>
        </Edit>
    );
};
