import {
    Refine,
    GitHubBanner,
    WelcomePage,
    Authenticated,
} from '@refinedev/core';
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import axios, { AxiosInstance } from "axios";

import {
    AuthPage, ErrorComponent
    , notificationProvider
    , ThemedLayoutV2,
    ThemedTitleV2
} from '@refinedev/antd';
import "@refinedev/antd/dist/reset.css";

import nestjsxCrudDataProvider from "./dataProvider";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import routerBindings, { NavigateToResource, CatchAllNavigate, UnsavedChangesNotifier } from "@refinedev/react-router-v6";
import { CheckpointCreate, CheckpointEdit, CheckpointList } from "pages/checkpoints";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { Header } from "./components/header";
import { Login } from "pages/login";
import { API_URL, authProvider, TOKEN_KEY } from "./authProvider";
import { GiftGroupCreate, GiftGroupEdit, GiftGroupList } from 'pages/gift-groups';
import { GiftCreate, GiftEdit, GiftList } from 'pages/gifts';
import { LocationTypeCreate, LocationTypeEdit, LocationTypeList } from 'pages/location-types';
import { LocationCreate, LocationEdit, LocationList } from 'pages/locations';
import { Dashboard } from 'pages/dashboard';
import { GiftConfigEdit, GiftConfigList } from 'pages/gift-configs';

// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(TOKEN_KEY)}`;
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
        };
    }

    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

function App() {
    const dataProvider = nestjsxCrudDataProvider(API_URL, axios);

    return (
        <BrowserRouter>
            <RefineKbarProvider>
                <ColorModeContextProvider>
                    <Refine dataProvider={dataProvider}
                        notificationProvider={notificationProvider}
                        routerProvider={routerBindings}
                        authProvider={authProvider}
                        resources={[
                            {
                                name: "dashboard",
                                identifier: "dashboard",
                                list: "/dashboard",
                                meta: {
                                    label: 'Dashboard'
                                },
                            },
                            {
                                name: "gift-configs",
                                identifier: "gift-configs",
                                list: "/gift-configs",
                                edit: "/gift-configs/:id/edit",
                                meta: {
                                    canDelete: false,
                                    label: 'Quản lý số lượng quà'
                                },
                            },
                            {
                                name: "checkpoints",
                                identifier: "checkpoints",
                                list: "/checkpoints",
                                edit: "/checkpoints/:id/edit",
                                create: "/checkpoints/new",
                                meta: {
                                    canDelete: false,
                                    label: 'Quản lý trải nghiệm'
                                },
                            },
                            {
                                name: "gift-groups",
                                identifier: "gift-groups",
                                list: "/gift-groups",
                                edit: "/gift-groups/:id/edit",
                                create: "/gift-groups/new",
                                meta: {
                                    canDelete: true,
                                    label: 'Quản lý nhóm quà'
                                },
                            },
                            {
                                name: "gifts",
                                identifier: "gifts",
                                list: "/gifts",
                                edit: "/gifts/:id/edit",
                                create: "/gifts/new",
                                meta: {
                                    canDelete: true,
                                    label: 'Quản lý quà'
                                },
                            },
                            {
                                name: "location-types",
                                identifier: "location-types",
                                list: "/location-types",
                                edit: "/location-types/:id/edit",
                                create: "/location-types/new",
                                meta: {
                                    canDelete: true,
                                    label: 'Quản lý loại địa điểm'
                                },
                            },
                            {
                                name: "locations",
                                identifier: "locations",
                                list: "/locations",
                                edit: "/locations/:id/edit",
                                create: "/locations/new",
                                meta: {
                                    canDelete: true,
                                    label: 'Quản lý địa điểm'
                                },
                            },
                        ]}
                        options={{
                            syncWithLocation: true,
                            warnWhenUnsavedChanges: true,
                        }}
                    >
                        <Routes>
                            <Route
                                element={
                                    <Authenticated
                                        key="dashboard"
                                        fallback={<CatchAllNavigate to="/login" />}
                                    >
                                        <ThemedLayoutV2
                                            Header={Header}
                                            Title={() => <ThemedTitleV2 collapsed={false} text="YFest Admin" />}
                                        >
                                            <Outlet />
                                        </ThemedLayoutV2>
                                    </Authenticated>
                                }
                            >
                                <Route index element={
                                    <NavigateToResource resource="dashboard" />
                                } />
                                <Route path="/dashboard">
                                    <Route index element={<Dashboard />} />
                                </Route>
                                <Route path="/gift-configs">
                                    <Route index element={<GiftConfigList />} />
                                    <Route path=":id/edit" element={<GiftConfigEdit />} />
                                </Route>
                                <Route path="/checkpoints">
                                    <Route index element={<CheckpointList />} />
                                    <Route path="new" element={<CheckpointCreate />} />
                                    <Route path=":id/edit" element={<CheckpointEdit />} />
                                </Route>
                                <Route path="/gift-groups">
                                    <Route index element={<GiftGroupList />} />
                                    <Route path="new" element={<GiftGroupCreate />} />
                                    <Route path=":id/edit" element={<GiftGroupEdit />} />
                                </Route>
                                <Route path="/gifts">
                                    <Route index element={<GiftList />} />
                                    <Route path="new" element={<GiftCreate />} />
                                    <Route path=":id/edit" element={<GiftEdit />} />
                                </Route>
                                <Route path="/location-types">
                                    <Route index element={<LocationTypeList />} />
                                    <Route path="new" element={<LocationTypeCreate />} />
                                    <Route path=":id/edit" element={<LocationTypeEdit />} />
                                </Route>
                                <Route path="/locations">
                                    <Route index element={<LocationList />} />
                                    <Route path="new" element={<LocationCreate />} />
                                    <Route path=":id/edit" element={<LocationEdit />} />
                                </Route>
                            </Route>
                            <Route
                                element={
                                    <Authenticated
                                        key="login"
                                        fallback={<Outlet />}
                                    >
                                        <NavigateToResource />
                                    </Authenticated>
                                }
                            >
                                <Route path="/login" element={<Login />} />
                                {/* <Route path="/register" element={<Register />} />
                                <Route path="/forgot-password" element={<ForgotPassword />} /> */}
                            </Route>
                            <Route
                                element={
                                    <Authenticated
                                        key="unauthenticated"
                                    >
                                        <ThemedLayoutV2
                                            Header={Header}
                                        >
                                            <Outlet />
                                        </ThemedLayoutV2>
                                    </Authenticated>
                                }
                            >
                                <Route path="*" element={<ErrorComponent />} />
                            </Route>
                        </Routes>
                        <RefineKbar />
                        <UnsavedChangesNotifier />
                    </Refine>
                </ColorModeContextProvider>
            </RefineKbarProvider>
        </BrowserRouter>
    );
};

export default App;
