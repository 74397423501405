import { AuthBindings } from "@refinedev/core";
import axios, { AxiosInstance } from "axios";

export const TOKEN_KEY = "refine-auth";

// export const BASE_URL = "http://localhost:8686";
export const BASE_URL = "https://event-api.hoccontentcunglina.com";
export const API_URL = `${BASE_URL}/api/v1`;

export const authProvider: AuthBindings = {
  login: async ({ username, email, password }) => {
    if ((username || email) && password) {
      const { data } = await axios.post(`${API_URL}/auth/login`, {
        username: email,
        password,
      });

      if (data.data.token) {
        localStorage.setItem(TOKEN_KEY, data.data.token);
        return {
          success: true,
          redirectTo: "/",
        };
      }
    }

    return {
      success: false,
      error: {
        name: "LoginError",
        message: "Invalid username or password",
      },
    };
  },
  logout: async () => {
    localStorage.removeItem(TOKEN_KEY);
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      const { data } = await axios.get(`${API_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data.data;
    }
    return null;
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
};
